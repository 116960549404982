export default {
  paddingtype: {
    label: "Padding Type",
    checkbox: null,
    field: ["Default", "Remove Top Padding", "Remove Bottom Padding"],
    selected: "Default",
  },

  enablerow1: {
    label: "Enable Row 1",
    checkbox: true,
    field: null,
  },

  row1: {
    label: "Row 1: Layout Type",
    checkbox: null,
    field: ["Single Column", "Multi-Column", "Column Flow", "Chart Bar", "Stacked"],
    selected: "Single Column",
  },

  row1numcol: {
    label: "Row 1: Number of Columns [Multi-Column and Column Flow]",
    checkbox: null,
    field: [2, 3],
    selected: 2,
  },

  row1options: [
    {
      label: "Row 1: Enable Ordered List",
      checkbox: true,
      field: null,
      block: 1,
    },
    {
      label: "Row 1: Enable Unordered List",
      checkbox: true,
      field: null,
      block: 1,
    },
    {
      label: "Row 1: Enable Superscripts & Subscripts",
      checkbox: true,
      field: null,
      block: 1,
    },
    {
      label: "Row 1: Enable Image",
      checkbox: true,
      field: null,
      block: 1,
    },
    {
      label: "Row 1: Enable Figure",
      checkbox: true,
      field: null,
      block: 1,
    },
    {
      label: "Row 1: Enable Blockquote",
      checkbox: true,
      field: null,
      block: 1,
    },
    {
      label: "Row 1: Enable Video",
      checkbox: true,
      field: null,
      block: 1,
    },
    {
      label: "Row 1: Enable Button(s)",
      checkbox: true,
      field: null,
      block: 1,
    },
  ],

  buttonvariation: {
    label: "Row 1: Button Variations",
    checkbox: null,
    field: ["1 Primary", "1 Primary (Alt)", "2 Primary (Alt)"],
    selected: "2 Primary (Alt)",
  },

  button1: {
    label: "Row 1: Button 1",
    checkbox: null,
    field: "Link with a button class",
  },

  button2: {
    label: "Row 1: Button 2",
    checkbox: null,
    field: "Button",
  },

  enablerow2: {
    label: "Enable Row 2",
    checkbox: true,
    field: null,
  },

  row2: {
    label: "Row 2: Layout Type",
    checkbox: null,
    field: ["Single Column", "Multi-Column", "Column Flow", "Chart Bar", "Stacked"],
    selected: "Multi-Column",
  },

  row2numcol: {
    label: "Row 2: Number of Columns [Multi-Column and Column Flow]",
    checkbox: null,
    field: [2, 3],
    selected: 2,
  },

  row2options: [
    {
      label: "Row 2: Enable Ordered List",
      checkbox: true,
      field: null,
      block: 2,
    },
    {
      label: "Row 2: Enable Unordered List",
      checkbox: true,
      field: null,
      block: 2,
    },
    {
      label: "Row 2: Enable Superscripts & Subscripts",
      checkbox: true,
      field: null,
      block: 2,
    },
    {
      label: "Row 2: Enable Image",
      checkbox: true,
      field: null,
      block: 2,
    },
    {
      label: "Row 2: Enable Figure",
      checkbox: true,
      field: null,
      block: 2,
    },
    {
      label: "Row 2: Enable Blockquote",
      checkbox: true,
      field: null,
      block: 2,
    },
  ],

  enablerow3: {
    label: "Enable Row 3",
    checkbox: true,
    field: null,
  },

  row3: {
    label: "Row 3: Layout Type",
    checkbox: null,
    field: ["Single Column", "Multi-Column", "Column Flow", "Chart Bar", "Stacked"],
    selected: "Multi-Column",
  },

  row3numcol: {
    label: "Row 3: Number of Columns [Multi-Column and Column Flow]",
    checkbox: null,
    field: [2, 3],
    selected: 3,
  },

  row3options: [
    {
      label: "Row 3: Enable Ordered List",
      checkbox: true,
      field: null,
      block: 3,
    },
    {
      label: "Row 3: Enable Unordered List",
      checkbox: true,
      field: null,
      block: 3,
    },
    {
      label: "Row 3: Enable Superscripts & Subscripts",
      checkbox: true,
      field: null,
      block: 3,
    },
    {
      label: "Row 3: Enable Image",
      checkbox: true,
      field: null,
      block: 3,
    },
    {
      label: "Row 3: Enable Figure",
      checkbox: true,
      field: null,
      block: 3,
    },
    {
      label: "Row 3: Enable Blockquote",
      checkbox: true,
      field: null,
      block: 3,
    },
  ],

  enablerow4: {
    label: "Enable Row 4: Column Flow 2",
    checkbox: true,
    field: null,
  },

  enablerow5: {
    label: "Enable Row 5: Column Flow 3",
    checkbox: true,
    field: null,
  },

  enablerow6: {
    label: "Enable Row 6: Chart Bar",
    checkbox: true,
    field: null,
  },

  enablerow7: {
    label: "Enable Row 7: Stacked",
    checkbox: true,
    field: null,
  },

  chartbarlength: {
    label: "Chart Bar: Length",
    checkbox: null,
    field: [
      "5%",
      "10%",
      "15%",
      "20%",
      "25%",
      "30%",
      "35%",
      "40%",
      "45%",
      "50%",
      "55%",
      "60%",
      "65%",
      "70%",
      "75%",
      "80%",
      "85%",
      "90%",
      "95%",
      "100%",
    ],
    selected: "90%",
  },

  chartbartitle: {
    label: "Chart Bar: Title",
    checkbox: true,
    field: "Lorem ipsum",
  },
  chartbarcopy: {
    label: "Chart Bar: Copy",
    checkbox: true,
    field: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
  chartbarcopycolor: {
    label: "Chart Bar: Enable Dark Copy Color",
    checkbox: false,
    field: null,
  },
  chartbarcolor: {
    label: "Chart Bar: Color",
    checkbox: null,
    field: "#c8102e",
  },
  stackedtitle: {
    label: "Stacked: Title",
    checkbox: null,
    field: "Speed",
  },
  stackedcopy: {
    label: "Stacked: Copy",
    checkbox: null,
    field: "26MHz",
  },
  // iconsoptions: {
  //   label: "Enable Icons",
  //   checkbox: true,
  //   field: null,
  // },
  // iconalignment: {
  //   label: "Icon Alignment",
  //   checkbox: null,
  //   field: ["Left", "Center"],
  //   selected: "Left",
  // },

  heading1: {
    label: "Heading",
    checkbox: true,
    field: "Heading Column 1",
  },
  subheading1: {
    label: "Subheading",
    checkbox: true,
    field: "Subheading Column 1",
  },
  p1: {
    label: "Paragraph 1",
    checkbox: null,
    field:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe numquam. Enim maxime veritatis ea quia eos quam et sunt amet voluptatem aperiam, necessitatibus, est.",
  },
  link1: {
    label: "Link Text",
    checkbox: null,
    field: "Link Text",
  },
  tooltip: {
    label: "Tooltip",
    checkbox: null,
    field: "labore",
  },
  tooltipmessage: {
    label: "Tooltip Message",
    checkbox: null,
    field:
      "rci porta non pulvinar neque laoreet. Orci ac auctor augue mauris augue neq Commodo sed egestas egestas fringilla phasellu faucibus scelerisque eleifend. Eget est lorem ipsum dolor sitllentesque massa. Nunc non blandit massa enim nec dui nunc. Ante metus dictum at tempor commodo ullamcorper a. Tellus molestie",
  },
  listitem: {
    label: "List Item",
    checkbox: null,
    field: "List Item 1",
  },
  supsubfoot: [
    {
      label: "Superscript",
      checkbox: null,
      field: "5",
    },
    {
      label: "Footnote",
      checkbox: null,
      field: "5",
    },
    {
      label: "Subscript",
      checkbox: null,
      field: "low",
    },
  ],
  // figureoverlay: {
  //   label: "Enable Figure Overlay",
  //   checkbox: true,
  //   field: null,
  // },
  figurecaption: {
    label: "Figure Caption",
    checkbox: null,
    field: "Figure Caption Example",
  },
  figurealignment: {
    label: "Figure Alignment",
    checkbox: null,
    field: ["Default", "Float Left", "Float Right"],
    selected: "Default",
  },

  blockquote: [
    {
      label: "Blockquote Copy",
      checkbox: null,
      field: "His words of wisdom fall on deaf ears but some how this team wins!",
    },
    {
      label: "Blockquote Verbage [not on cms]",
      checkbox: true,
      field: "explained",
    },
    {
      label: "Blockquote Author [not on cms]",
      checkbox: true,
      field: "Diablo Parasecoli",
    },
    {
      label: "Blockquote Title [not on cms]",
      checkbox: true,
      field: "Manager of the Technical Operations Division",
    },
  ],
};
