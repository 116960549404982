import React from "react";

const C_ImageCarousel = () => {
  return (
    <div
      className="c-imageCarousel"
      role="region"
      aria-label="Image Carousel"
      aria-roledescription="carousel"
    >
      <div className="c-imageCarousel__display" aria-live="polite">
        <div
          className="c-imageCarousel__display__item c-imageCarousel__display__item--active"
          style={{
            "--zoom-x": "0%",
            "--zoom-y": "0%",
          }}
        >
          <picture className="c-imageCarousel__display__item__image">
            <source
              srcSet="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-1-lg.jpg"
              media="(min-width: 32em)"
            />
            <img
              src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-1-sm.jpg"
              alt="Image 1"
              title="Image 1"
            />
          </picture>
          <img
            className="c-imageCarousel__display__item__zoom"
            src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-1-zm-lg.jpg"
            alt="Image 1"
            title="Image 1"
          />
        </div>
        <div className="c-imageCarousel__display__item">
          <picture className="c-imageCarousel__display__item__image">
            <source
              srcSet="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-2-lg.jpg"
              media="(min-width: 32em)"
            />
            <img
              src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-2-sm.jpg"
              alt="Image 2"
              title="Image 2"
            />
          </picture>
          <img
            className="c-imageCarousel__display__item__zoom"
            src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-2-zm-lg.jpg"
            alt="Image 2"
            title="Image 2"
          />
        </div>
        <div className="c-imageCarousel__display__item">
          <picture className="c-imageCarousel__display__item__image">
            <source
              srcSet="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-3-lg.jpg"
              media="(min-width: 32em)"
            />
            <img
              src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-3-sm.jpg"
              alt="Image 3"
              title="Image 3"
            />
          </picture>
          <img
            className="c-imageCarousel__display__item__zoom"
            src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-3-zm-lg.jpg"
            alt="Image 3"
            title="Image 3"
            // style={
            //   {
            //     "--url": "url(https://media.kingston.com/kingston/product/SXS1000_1000GB_pkg-zm-lg.jpg)",
            //   }
            // }
          />
        </div>
        <div className="c-imageCarousel__display__item">
          <div
            className="c-externalVideo"
            data-type="Youtube"
            data-id="q1DOk2vRsus"
            data-starttime="1"
          ></div>
        </div>
        <div className="c-imageCarousel__display__item">
          <div
            className="c-externalVideo"
            data-type="Vimeo"
            data-id="848071418"
            data-starttime="1"
          ></div>
        </div>

        <div className="c-imageCarousel__display__controls">
          <button
            className="c-imageCarousel__display__controls__btn c-imageCarousel__display__controls__btn--back"
            aria-label="Previous Image"
          >
            <svg viewBox="0 0 24 24">
              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
            </svg>
          </button>
          <button
            className="c-imageCarousel__display__controls__btn c-imageCarousel__display__controls__btn--forward"
            aria-label="Next Image"
          >
            <svg viewBox="0 0 24 24">
              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="c-imageCarousel__thumbnails">
        <button
          className="c-imageCarousel__thumbnails__thumbnail c-imageCarousel__thumbnails__thumbnail--active"
          aria-label="Image 1 of 5"
          aria-controls="c-imageCarousel__display"
          aria-selected="true"
          tabIndex="0"
        >
          <img
            src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-1-tn.jpg"
            alt="Image 1"
            title="Image 1"
          />
        </button>
        <button
          className="c-imageCarousel__thumbnails__thumbnail"
          aria-label="Image 2 of 5"
          aria-controls="c-imageCarousel__display"
          aria-selected="false"
          tabIndex="-1"
        >
          <img
            src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-2-tn.jpg"
            alt="Image 2"
            title="Image 2"
          />
        </button>
        <button
          className="c-imageCarousel__thumbnails__thumbnail"
          aria-label="Image 3 of 5"
          aria-controls="c-imageCarousel__display"
          aria-selected="false"
          tabIndex="-1"
        >
          <img
            src="https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-3-tn.jpg"
            alt="Image 3"
            title="Image 3"
          />
        </button>
        <button
          className="c-imageCarousel__thumbnails__thumbnail"
          aria-label="Image 4 of 5"
          aria-controls="c-imageCarousel__display"
          aria-selected="false"
          tabIndex="-1"
        >
          <img
            src="https://media.kingston.com/kingston/product/SXS1000_1000GB_pkg-tn.jpg"
            alt="Image 3"
            title="Image 3"
          />
          <svg viewBox="0 0 73 73">
            <path d="M0 36.5a36.5 36.5 0 1073 0 36.5 36.5 0 10-73 0" fill="rgba(0,0,0,0.5)" />
            <path d="M36.5 9A27.5 27.5 0 119 36.5 27.5 27.5 0 0136.5 9m0-5.5a33 33 0 1033 33 33 33 0 00-33-33z" />
            <path d="M52.56 37.36l-23 13.74a1 1 0 01-1.56-.86V22.76a1 1 0 011.51-.86l23 13.74a1 1 0 010 1.72z" />
          </svg>
        </button>
        <button
          className="c-imageCarousel__thumbnails__thumbnail"
          aria-label="Image 3 of 3"
          aria-controls="c-imageCarousel__display"
          aria-selected="false"
          tabIndex="-1"
        >
          <img
            src="https://media.kingston.com/kingston/product/SXS1000_1000GB_pkg-tn.jpg"
            alt="Image 3"
            title="Image 3"
          />
          <svg viewBox="0 0 73 73">
            <path d="M0 36.5a36.5 36.5 0 1073 0 36.5 36.5 0 10-73 0" fill="rgba(0,0,0,0.5)" />
            <path d="M36.5 9A27.5 27.5 0 119 36.5 27.5 27.5 0 0136.5 9m0-5.5a33 33 0 1033 33 33 33 0 00-33-33z" />
            <path d="M52.56 37.36l-23 13.74a1 1 0 01-1.56-.86V22.76a1 1 0 011.51-.86l23 13.74a1 1 0 010 1.72z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default C_ImageCarousel;
